export const isBase64 = (str: string): boolean => {
  try {
    return btoa(atob(str)) === str
  } catch {
    return false
  }
}

export const safeParseBase64Json = (value: string): unknown => {
  if (!value) return null
  
  try {
    // First try to parse as base64
    if (isBase64(value)) {
      const decoded = atob(value)
      // Convert UTF-8 bytes back to Unicode string
      const text = decodeURIComponent(decoded)
      return JSON.parse(text)
    }
    // If not base64, try parsing as regular JSON
    return JSON.parse(value)
  } catch {
    return null
  }
}

export const toBase64Json = (data: unknown): string => {
  if (data === undefined) {
    return btoa('null')
  }

  try {
    const jsonString = JSON.stringify(data)
    // Convert Unicode to UTF-8 bytes
    const utf8String = encodeURIComponent(jsonString)
    return btoa(utf8String)
  } catch {
    return btoa('null')
  }
}